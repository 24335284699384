/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

// Third party
import styled, { css } from 'styled-components'
import { useMedia } from 'react-use'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
  BlogBinkFilter,
  BlogBinkPagination,
} from '@ubo/blog-bink'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import useLayout from 'hooks/useLayout'
import Button from 'components/elements/Buttons/Button'

// Images
import Clock from 'img/clock.inline.svg'
import Pin from 'img/pin.inline.svg'
import Bars from 'img/bars.inline.svg'
import Education from 'img/education.inline.svg'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledVacancies = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;

  @media (max-width: 991px) {
    margin-top: 9rem;
  }
`
const FilterOpenButton = styled.div`
  position: fixed;
  background: linear-gradient(90deg, #09154f -12.53%, #1402ac 90.99%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: fit-content;
  bottom: 0;
  left: 0;
  z-index: 20;
  padding: 20px;

  & > div {
    font-size: 20px;
    color: ${({ theme }) => theme.color.text.light};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
    cursor: pointer;

    & b,
    strong {
      color: ${({ theme }) => theme.color.text.secondary};
    }
  }
`

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    nl,
    en,
    nlOpen,
    enOpen,
    enCategory,
    nlCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.vacanciesQueryQuery>(graphql`
    query vacanciesQuery {
      en: allWpVacature(
        filter: { locale: { locale: { eq: "en_US" } }, databaseId: { ne: 755 } }
        sort: { order: ASC, fields: date }
      ) {
        edges {
          node {
            ...previewVacancyFragment
          }
        }
      }
      nl: allWpVacature(
        filter: { locale: { locale: { eq: "nl_NL" } }, databaseId: { ne: 646 } }
        sort: { order: ASC, fields: date }
      ) {
        edges {
          node {
            ...previewVacancyFragment
          }
        }
      }
      nlOpen: wpVacature(databaseId: { eq: 646 }) {
        id
        ...previewVacancyFragment
      }
      enOpen: wpVacature(databaseId: { eq: 755 }) {
        id
        ...previewVacancyFragment
      }

      enCategory: allWpCategory(filter: {vacatures: {nodes: {elemMatch: {locale: {locale: {eq: "en_US"}}}}}, name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            databaseId
            name
            wpParent {
              node {
                databaseId
                name
              }
            }
            wpChildren {
              nodes {
                id
                databaseId
                name
                wpParent {
                  node {
                    databaseId
                    name
                  }
                }
              }
            }
          }
        }
      }

      nlCategory: allWpCategory(filter: {vacatures: {nodes: {elemMatch: {locale: {locale: {eq: "nl_NL"}}}}}, name: { nin: "Geen onderdeel van een categorie" } }) {
        edges {
          node {
            id
            databaseId
            name
            wpParent {
              node {
                databaseId
                name
              }
            }
            wpChildren {
              nodes {
                id
                databaseId
                name
                wpParent {
                  node {
                    databaseId
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const layout = useLayout()

  const vacancyLiterals: { [key: string]: any } = {
    nl_NL: nl,
    en_US: en,
    default: en,
  }

  const openLiterals: { [key: string]: any } = {
    nl_NL: nlOpen,
    en_US: enOpen,
    default: enOpen,
  }


  const categoryLiterals: { [key: string]: any } = {
    nl_NL: nlCategory,
    en_US: enCategory,
    default: enCategory,
  }

  const open = openLiterals[layout.locale || 'default']
  const posts = vacancyLiterals[layout.locale || 'default'].edges
  const wpCategories = categoryLiterals[layout.locale || 'default'].edges
  const mainCategories = wpCategories.filter(
    (category) => !category.node.wpParent
  )
  const subCategories = wpCategories.filter(
    (category) => category.node.wpParent
  )  
  const mobile = useMedia(`(max-width: 991px)`)
  const [openFilter, setOpenFilter] = React.useState(false)

  return (
    <StyledVacancies>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={subCategories}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="container py-5">
          <div className="row justify-content-between">
            <div className="col-lg-4">
            {mobile ? (
                <>
                  <FilterOpenButton>
                    <div
                      role="button"
                      tabIndex={-1}
                      onKeyDown={() => setOpenFilter(!openFilter)}
                      onClick={() => setOpenFilter(!openFilter)}
                    >
                      {!openFilter ? (
                        <div>
                          Open filter<b>_</b>
                        </div>
                      ) : (
                        <div>
                          {layout.locale === 'nl_NL' ? (
                            <>
                              {`Laat vacature${
                                posts.length === 1 ? `` : `s`
                              } zien`}
                            </>
                          ) : (
                            <>
                              {`Show vacanc${
                                posts.length === 1 ? `y` : `ies`
                              }`}
                            </>
                          )}

                          <b>_</b>
                        </div>
                      )}
                    </div>
                  </FilterOpenButton>
                  {openFilter && (
                    <BlogFilters
                      mainCategories={mainCategories} 
                      fields={fields}
                    />
                  )}
                </>
              ) : (
              <BlogFilters mainCategories={mainCategories} fields={fields} />
            )}
            </div>
            <div className="col-lg-8">
              <BlogGrid open={open} fields={fields} />
            </div>
          </div>
        </div>
      </BlogBink>
    </StyledVacancies>
  )
}


const Categories = styled.div`
  @media (max-width: 991px) {
    height: 100%;
    overflow-y: scroll;
  }
`

const FilterTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.text.primaryLight};
  padding: 40px 0 15px 0;

  @media screen and (max-width: 991px) {
    padding: 20px 0 15px 0;
  }
`

const FilterOption = styled(BlogBinkFilter)<{ isSelected: boolean }>`
  font-size: 16px;
  line-height: 30px;
  padding-left: 25px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.text.dark};
  position: relative;
  cursor: pointer;
  display: block;

  &::before {
    content: '';
    height: 15px;
    width: 15px;
    border: 1px solid ${({ theme }) => theme.color.greyDark};
    position: absolute;
    left: 0px;
    top: 7px;

    ${({ isSelected, theme }) =>
      isSelected
        ? css`
            background-color: ${theme.color.secondary};
          `
        : css`
            background-color: ${theme.color.light};
          `}
  }
`

const StyledBlogFilters = styled.div<{ darkmode: boolean }>`
  background-color: ${({ theme }) => theme.color.grey};
  transition: all 0.3s ease;
  padding: 25px 40px 200px 0;
  margin-top: -150px;
  max-width: 350px;
  position: relative;
  z-index: 5;

  ${({ darkmode }) =>
    darkmode
      ? css`
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
        `
      : css`
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        `}

  &::before {
    content: '';
    background-color: ${({ theme }) => theme.color.grey};
    transition: all 0.3s ease;
    width: 100px;
    left: -100px;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 5;
  }

  @media screen and (max-width: 991px) {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 15;
    margin-top: unset;
    max-width: unset;
    padding: 50px 100px;
  }

  @media screen and (max-width: 575px) {
    padding: 10px 50px;
    overflow: scroll;
  }
`

const Title = styled.div`
  font-size: 30px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 70px;
  color: ${({ theme }) => theme.color.secondary};
  font-family: ${({ theme }) => theme.font.family.secondary};
`


interface BlogFiltersProps {
  mainCategories: any
  fields: any
}

interface BlogFilterProps {
  node: {
    id: string
    name: string
    wpParent: any
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = ({
  mainCategories,
  fields,
}: any) => {
  const [open, setOpen] = useState(-1)
  const blogBink = useBlogBink()
  const layout = useLayout()

  return (
    <StyledBlogFilters darkmode={layout.darkMode}>
      <Title>Filters</Title>
      <Categories>
        {mainCategories.map((mainCategory: any, index: number) => (
          <div key={`cat-${index}`}>
            <FilterTitle>
              {mainCategory.node.name}
            </FilterTitle>
              {blogBink.categories
                .filter(
                  (category: BlogFilterProps) =>
                    category.node.wpParent.node.databaseId ===
                    mainCategory.node.databaseId
                )
                .map(
                  (category: BlogFilterProps, categoryIndex: number) => (
              <>
                <FilterOption
                  isSelected={blogBink.isSelected(category.node.id)}
                  key={`${category.node.id}-${categoryIndex}`}
                  id={category.node.id}
                  className="mr-3"
                >
                 {category.node.name}
                </FilterOption>
              </>
            ))}
          </div>
        ))}
      </Categories>
    </StyledBlogFilters>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  open: any
}

const StyledBlogGrid = styled.div`
  margin-top: -150px;
  position: relative;
  z-index: 5;

  @media screen and (max-width: 991px) {
    margin-top: -200px;
  }
`

const NothingFound = styled.div<{ darkmode: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
  padding: 40px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.text.fatPrimaryLight};
  font-size: 30px;

  & p {
    font-size: 20px;
  }

  ${({ darkmode }) =>
    darkmode
      ? css`
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
        `
      : css`
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        `}
`

const StyledBlogBinkPagination = styled(BlogBinkPagination)`
  & button {
    background-color: ${({ theme }) => theme.color.light};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin: 0 10px;
    padding: 6px 14px;
    color: ${({ theme }) => theme.color.greyDark};

    &[data-active='1'] {
      color: ${({ theme }) => theme.color.text.fatPrimaryLight};
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`


const BlogGrid: React.FC<BlogGridProps> = ({ open, fields }) => {
  const blogBink = useBlogBink()

  const layout = useLayout()
  
  return (
    <StyledBlogGrid>
      <div className="row">
        {blogBink.posts.map((post, index) => {
          const { node }: any = post
          
          return (
            <>
              <div key={post.node.id} className="col-lg-12 mb-3">
                <BlogGridPost node={node} blogFields={fields} />
              </div>
              {((blogBink.posts.length < 2 && index === 0) ||
                (blogBink.posts.length < 3 && index === 1) ||
                index === 2) && (
                <div className="col-lg-12 mb-3">
                  <OpenBlogGridPost node={open} blogFields={fields} />
                </div>
              )}
            </>
          )
        })}
        {blogBink.posts.length === 0 && (
          <>
            <div className="col-lg-12 mb-3">
              <NothingFound darkmode={layout.darkMode}>
                <div>Helaas zijn er geen vacatures voor deze filters</div>
                <div className="d-flex pt-4 justify-content-between">
                  <p className="mb-0">Wel benieuwd naar onze diensten?</p>
                  <Button
                    className="filled secondary hover-secondary"
                    to="/contact/"
                  >
                    Neem contact op
                  </Button>
                </div>
              </NothingFound>
            </div>
            <div className="col-lg-12 mb-3">
              <OpenBlogGridPost node={open} blogFields={fields} />
            </div>
          </>
        )}
        <div>
          {blogBink.showPagination && (
            <div className="pt-5 d-flex justify-content-end">
              <StyledBlogBinkPagination />
            </div>
          )}
        </div>
      </div>
    </StyledBlogGrid>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpVacature
}

const StyledBlogGridPost = styled.div<{ top: boolean; darkmode: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
  ${({ darkmode }) =>
    darkmode
      ? css`
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
        `
      : css`
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        `}

  padding: 40px 60px;
  position: relative;
  z-index: 2;

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-size: 20px;
    line-height: 20px;
  }

  & h3 {
    font-size: 16px;
    color: ${({ theme }) => theme.color.dark};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  @media screen and (max-width: 991px) {
    ${({ top }) =>
      top
        ? css`
            padding: 30px 15px 10px 15px;
          `
        : css`
            padding: 10px 15px;
          `}
  }
`

const Block = styled.div<{ darkmode: boolean }>`
  position: relative;

  & svg {
    margin-right: 10px;

    & path {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              fill: ${theme.color.secondary};
            `
          : css`
              fill: ${theme.color.primaryLight};
            `}
    }

    & rect {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              stroke: ${theme.color.secondary};
            `
          : css`
              stroke: ${theme.color.primaryLight};
            `}
    }
  }
`

const TopVacancy = styled.div`
  position: absolute;
  box-shadow: inset 0px 0px 0px 2px
    ${({ theme }) => theme.color.border.primaryLight};
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  & > div {
    padding: 0 60px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.color.border.primaryLight};
    transition: all 0.3s ease;
    color: ${({ theme }) => theme.color.text.altLight};
    font-size: 25px;
    font-weight: ${({ theme }) => theme.font.weight.bold};

    & b {
      color: ${({ theme }) => theme.color.text.secondary};
    }
  }

  @media screen and (max-width: 991px) {
    & > div {
      padding: 0 50px;
      font-size: 16px;
    }
  }
`

const TitleWrapper = styled.div`
  @media screen and (max-width: 350px) {
    flex-wrap: wrap-reverse;

    & a,
    button {
      position: relative;
      left: -8px;
    }
  }
`

const SvgWrapper = styled.div`
  @media screen and (max-width: 575px) {
    width: 30px;
    display: flex;
    justify-content: flex-start;
  }
`

const BlogContent = styled(ParseContent)`
  & p,span {
    color: #000 !important; 
  }
`

const BlogDarkContent = styled(ParseContent)`
   & p,span {
    color: #fff !important; 
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const layout = useLayout()

  return (
    <StyledBlogGridPost
      top={node.vacancypreview?.topvacancy === 'true'}
      darkmode={layout.darkMode}
    >
      {node.vacancypreview?.topvacancy === 'true' && (
        <TopVacancy>
          <div className="d-flex">
            {layout.locale === 'nl_NL' ? (
              <div>
                Topvacature<b>_</b>
              </div>
            ) : (
              <div>
                Top vacancy<b>_</b>
              </div>
            )}
          </div>
        </TopVacancy>
      )}
      <Block darkmode={layout.darkMode}>
        <TitleWrapper className="d-flex justify-content-between align-items-center">
          <h2>{node?.title}</h2>
          <Button
            arrow
            className="secondary transparent hover-primaryAlt"
            to={node?.uri || '/'}
          >
            {blogFields.readmoretext}
          </Button>
        </TitleWrapper>
        <div className="row pt-2">
          <h3 className="col-sm d-flex align-items-center">
            <SvgWrapper>
              <Clock />
            </SvgWrapper>
            <span>{node?.vacancypreview?.type?.join('| ')}</span>
          </h3>
          <h3 className="col-sm d-flex align-items-center">
            <SvgWrapper>
              <Pin />
            </SvgWrapper>
            {node?.vacancypreview?.location?.join(', ')}
          </h3>
          <h3 className="col-sm d-flex align-items-center">
            <SvgWrapper>
              <Bars />
            </SvgWrapper>
            {node?.vacancypreview?.niveau?.join(', ')}
          </h3>
          <h3 className="col-sm d-flex align-items-center">
            <SvgWrapper>
              <Clock />
            </SvgWrapper>
            <span>{node?.vacancypreview?.hours?.hour}</span>
            {node?.vacancypreview?.hours?.hourtype === 'range' && (
              <span>-{node?.vacancypreview?.hours.hourend}</span>
            )}
            <span>&nbsp;uur</span>
          </h3>
          {node.vacancypreview?.education?.length > 0 && (
            <h3 className="col-sm d-flex align-items-center">
              <SvgWrapper>
                <Education />
              </SvgWrapper>
              {node?.vacancypreview?.education?.join(', ')}
            </h3>
          )}
        </div>
        <div className="pt-lg-4 pt-2">
          {layout.darkMode ? (
            <BlogDarkContent content={node.vacancypreview?.description || ''} />
          ) : (
            <BlogContent content={node.vacancypreview?.description || ''} />
          )}
          
        </div>
      </Block>
    </StyledBlogGridPost>
  )
}

const StyledOpenBlogGridPost = styled.div<{ darkmode: boolean }>`
  background: linear-gradient(90deg, #09154f -12.53%, #1402ac 90.99%);
  ${({ darkmode }) =>
    darkmode
      ? css`
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
        `
      : css`
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        `}

  padding: 40px 60px;
  position: relative;
  z-index: 2;

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.text.light};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-size: 20px;
    line-height: 20px;
  }

  & h3 {
    font-size: 16px;
    color: ${({ theme }) => theme.color.text.light};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
`

const OpenBlock = styled.div<{ darkmode: boolean }>`
  position: relative;

  & svg {
    margin-right: 10px;

    & path {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              fill: ${theme.color.secondary};
            `
          : css`
              fill: ${theme.color.secondary};
            `}
    }

    & rect {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              stroke: ${theme.color.secondary};
            `
          : css`
              stroke: ${theme.color.secondary};
            `}
    }
  }
`

const OpenBlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const layout = useLayout()

  return (
    <StyledOpenBlogGridPost darkmode={layout.darkMode}>
      {node.vacancypreview?.topvacancy === 'true' && (
        <TopVacancy>
          <div className="d-flex">
            {layout.locale === 'nl_NL' ? (
              <div>
                Topvacature<b>_</b>
              </div>
            ) : (
              <div>
                Top vacancy<b>_</b>
              </div>
            )}
          </div>
        </TopVacancy>
      )}
      <OpenBlock darkmode={layout.darkMode}>
        <TitleWrapper className="d-flex justify-content-between align-items-center">
          <h2>{node?.title}</h2>
          <Button
            arrow
            className="secondary transparent hover-light"
            to={node?.uri || '/'}
          >
            {layout.locale === 'nl_NL'
              ? `Verstuur open sollicitatie`
              : `Send open application`}
          </Button>
        </TitleWrapper>
        <div className="row pt-2">
          <h3 className="col-sm-3 d-flex align-items-center">
            <SvgWrapper>
              <Clock />
            </SvgWrapper>
            {node?.vacancypreview?.type}
          </h3>
          <h3 className="col-sm-3 d-flex align-items-center">
            <SvgWrapper>
              <Pin />
            </SvgWrapper>
            {node?.vacancypreview?.location}
          </h3>
          <h3 className="col-sm-3 d-flex align-items-center">
            <SvgWrapper>
              <Bars />
            </SvgWrapper>
            {node?.vacancypreview?.niveau}
          </h3>
        </div>
        <div className="pt-lg-4 pt-2">
          <ParseContent content={node.vacancypreview?.description || ''} />
        </div>
      </OpenBlock>
    </StyledOpenBlogGridPost>
  )
}


export default Blog