import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'

// Images
import Down from 'img/down.inline.svg'

// Hooks
import useLayout from 'hooks/useLayout'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkPosts,
  BlogBinkPagination,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledBlog = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
`

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    nl,
    en,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
    query blogQuery {
      nl: allWpPost(
        filter: { locale: { locale: { eq: "nl_NL" } } }
        sort: { order: ASC, fields: date }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }

      en: allWpPost(
        filter: { locale: { locale: { eq: "en_US" } } }
        sort: { order: ASC, fields: date }
      ) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const layout = useLayout()

  const postLiterals: { [key: string]: any } = {
    nl_NL: nl,
    en_US: en,
    default: en,
  }

  const posts = postLiterals[layout.locale || 'default'].edges

  return (
    <StyledBlog>
      <BlogBink
        posts={posts as BlogBinkPosts}
        pagination
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="py-lg-5 py-3">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </StyledBlog>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledBlogGrid = styled.div`
  margin-top: -250px;
  position: relative;
  z-index: 5;

  @media screen and (max-width: 991px) {
    margin-top: -200px;
  }
`

const StyledBlogBinkPagination = styled(BlogBinkPagination)`
  & button {
    background-color: ${({ theme }) => theme.color.light};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin: 0 10px;
    padding: 6px 14px;
    color: ${({ theme }) => theme.color.greyDark};

    &[data-active='1'] {
      color: ${({ theme }) => theme.color.text.fatPrimaryLight};
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <StyledBlogGrid className="container">
      <div className="row mt-lg-0 mt-4">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-xl-4 col-md-6 mt-lg-5 mt-3">
              <BlogGridPost node={node} blogFields={fields} />
            </div>
          )
        })}
      </div>

      {blogBink.showPagination && (
        <div className="pt-5 d-flex justify-content-end">
          <StyledBlogBinkPagination />
        </div>
      )}
    </StyledBlogGrid>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPost
}

const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 320px;
  z-index: 3;

  & p {
    font-size: 20px;
    line-height: 35px;
  }

  @media screen and (max-width: 991px) {
    & p {
      font-size: 16px;
      line-height: 28px;
    }
  }

  @media screen and (max-width: 575px) {
    bottom: 80px;
  }

  @media screen and (max-width: 350px) {
    bottom: 50px;
  }
`

const StyledBlogGridPost = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border: 3px solid ${({ theme }) => theme.color.secondary};
  border-radius: 40px;
  position: relative;
  padding: 40px;
  margin: 0 25px;
  overflow: hidden;
  height: 460px;

  @media screen and (max-width: 991px) {
    padding: 20px;
    margin: 0;
  }

  @media screen and (max-width: 575px) {
    height: 400px;
  }

  @media screen and (max-width: 350px) {
    height: 300px;
  }
`

const ButtonWrapper = styled(Link)`
  position: absolute;
  z-index: 4;
  bottom: 20px;
  right: 20px;

  & svg {
    transform: rotate(-90deg);

    & circle {
      transition: all 0.3s ease;
    }

    & path {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    & svg {
      & circle {
        fill: ${({ theme }) => theme.color.primary};
      }

      & path {
        stroke: ${({ theme }) => theme.color.secondary};
      }
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 40px;

  & img {
    border-radius: 40px;
  }
`

const Gradient = styled.div`
  background: linear-gradient(
    180deg,
    rgba(13, 14, 115, 0) 26.56%,
    #09154f 100%
  );
  position: absolute;
  width: 100%;
  height: 366px;
  bottom: 0;
  left: 0;
  z-index: 2;
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <StyledBlogGridPost>
    <StyledPlaatjie image={node.recap?.image} alt="" />
    <Gradient />
    <Content>
      <ParseContent content={node.recap?.description || ''} />
    </Content>
    <ButtonWrapper to={node.uri || '/'}>
      <Down />
    </ButtonWrapper>
  </StyledBlogGridPost>
)

export default Blog
