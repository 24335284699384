/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

// Images
import Clock from 'img/clock.inline.svg'
import Pin from 'img/pin.inline.svg'
import Bars from 'img/bars.inline.svg'
import LeftChip from 'img/chipleft.inline.svg'
import RightChip from 'img/chipright.inline.svg'

// Third Party
import styled, { css } from 'styled-components'

// Hooks
import useLayout from 'hooks/useLayout'

interface HighLightedVacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Content = styled(ParseContent)`
  & h2 {
    @media screen and (min-width: 992px) {
      font-size: 75px;
      line-height: 70px;
    }
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border: 3px solid ${({ theme }) => theme.color.border.primaryLight};
  border-radius: 45px;
  padding: 40px 60px;
  position: relative;
  z-index: 2;

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-size: 20px;
    line-height: 20px;
  }

  & h3 {
    font-size: 16px;
    color: ${({ theme }) => theme.color.dark};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  @media screen and (max-width: 575px) {
    padding: 10px 30px;
    border-radius: 25px;
  }
`

const Vacancy = styled.div<{ darkmode: boolean }>`
  position: relative;

  & svg {
    margin-right: 10px;

    & path {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              fill: ${theme.color.secondary};
            `
          : css`
              fill: ${theme.color.primaryLight};
            `}
    }

    & rect {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              stroke: ${theme.color.secondary};
            `
          : css`
              stroke: ${theme.color.primaryLight};
            `}
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.color.greyDark};
    height: 1px;
    left: 0;
  }

  @media screen and (max-width: 350px) {
    & h2 {
      font-size: 16px;
    }

    & button {
      font-size: 12px;
      position: relative;
      left: -15px;
    }
  }
`

const StyledHighlightedVacancies = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  padding: 50px 0;

  @media screen and (max-width: 1024px) {
    padding: 0 0 50px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 0;
  }
`

const LeftChipWrapper = styled.div<{ darkmode: boolean }>`
  position: absolute;
  right: 89%;
  top: -100px;
  z-index: 1;

  & svg {
    & path {
      ${({ darkmode }) =>
        darkmode
          ? css`
              fill: white;
              fill-opacity: 0.22;
            `
          : css`
              fill: black;
              fill-opacity: 0.1;
            `}
    }
  }

  @media screen and (max-width: 1024px) {
    right: 700px;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const RightChipWrapper = styled.div<{ darkmode: boolean }>`
  position: absolute;
  left: 85%;
  top: -80px;
  z-index: 1;

  & svg {
    & path {
      ${({ darkmode }) =>
        darkmode
          ? css`
              fill: white;
              fill-opacity: 0.22;
            `
          : css`
              fill: black;
              fill-opacity: 0.1;
            `}
    }
  }

  @media screen and (max-width: 1024px) {
    left: 650px;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const SvgWrapper = styled.div`
  @media screen and (max-width: 575px) {
    width: 30px;
    display: flex;
    justify-content: flex-start;
  }
`

const TitleWrapper = styled.div`
  @media screen and (max-width: 350px) {
    flex-wrap: wrap-reverse;
  }
`

const HighLightedVacancies: React.FC<HighLightedVacanciesProps> = ({
  fields,
}) => {
  console.log('hltdvac', fields.highlightedvacancies)

  // const language = getLanguage()
  const layout = useLayout()

  return (
    <StyledHighlightedVacancies>
      <div className="container py-lg-5 py-3">
        <Content content={fields.title || ''} />
        <div className="row py-lg-5 pt-lg-5 pt-3 pb-3 justify-content-center">
          <div className="col-xl-8 col-lg-10 position-relative">
            <LeftChipWrapper darkmode={layout.darkMode}>
              <LeftChip />
            </LeftChipWrapper>
            <RightChipWrapper darkmode={layout.darkMode}>
              <RightChip />
            </RightChipWrapper>
            <Block>
              {fields?.highlightedvacancies?.length > 0 &&
                fields.highlightedvacancies?.map((v, index) => (
                  <Vacancy
                    darkmode={layout.darkMode}
                    className="py-4"
                    key={index}
                  >
                    <TitleWrapper className="d-flex justify-content-between align-items-center">
                      <h2>{v?.title}</h2>
                      <Button
                        arrow
                        className="secondary transparent hover-primaryLight"
                        to={v?.uri || '/'}
                      >
                        {fields.readmoretext}
                      </Button>
                    </TitleWrapper>
                    <div className="row pt-2">
                      <h3 className="col-lg-4 d-flex align-items-center">
                        <SvgWrapper>
                          <Clock />
                        </SvgWrapper>
                        {v?.vacancypreview?.type}
                      </h3>
                      <h3 className="col-lg-4 d-flex align-items-center">
                        <SvgWrapper>
                          <Pin />
                        </SvgWrapper>
                        {v?.vacancypreview?.location}
                      </h3>
                      <h3 className="col-lg-4 d-flex align-items-center">
                        <SvgWrapper>
                          <Bars />
                        </SvgWrapper>
                        {v?.vacancypreview?.niveau}
                      </h3>
                    </div>
                  </Vacancy>
                ))}
              <div className="d-flex justify-content-center mt-3 pt-md-5 pb-lg-3 pb-1">
                <Button
                  arrow
                  className="filled secondary hover-secondary"
                  to={`${
                    layout.locale === 'nl_NL'
                      ? `https://app.loxo.co/career-dev-`
                      : `https://app.loxo.co/career-dev-`
                  }`}
                  target="_blank"
                >
                  {fields.loadmoretext}
                </Button>
              </div>
            </Block>
          </div>
        </div>
      </div>
    </StyledHighlightedVacancies>
  )
}

export default HighLightedVacancies
