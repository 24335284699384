/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Clock from 'img/clock.inline.svg'
import Pin from 'img/pin.inline.svg'
import Bars from 'img/bars.inline.svg'

// Components
import Button from 'components/elements/Buttons/Button'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Third Party
import styled, { css } from 'styled-components'
import useLayout from 'hooks/useLayout'
import ParseContent from 'components/shared/ParseContent'
import { useMedia } from 'react-use'

interface ProfilesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledProfiles = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
`

const FilterOpenButton = styled.div`
  position: fixed;
  background: linear-gradient(90deg, #09154f -12.53%, #1402ac 90.99%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: fit-content;
  bottom: 0;
  left: 0;
  z-index: 20;
  padding: 20px;

  & > div {
    font-size: 20px;
    color: ${({ theme }) => theme.color.text.light};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
    cursor: pointer;

    & b,
    strong {
      color: ${({ theme }) => theme.color.text.secondary};
    }
  }
`

const Profiles: React.FC<ProfilesProps> = ({ fields }) => {
  const {
    nl,
    en,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.profilesQueryQuery>(graphql`
    query profilesQuery {
      nl: allWpProfiel(
        filter: { locale: { locale: { eq: "nl_NL" } } }
        sort: { fields: date, order: ASC }
      ) {
        edges {
          node {
            id
            ...previewProfileFragment
          }
        }
      }

      en: allWpProfiel(
        filter: { locale: { locale: { eq: "en_US" } } }
        sort: { fields: date, order: ASC }
      ) {
        edges {
          node {
            id
            ...previewProfileFragment
          }
        }
      }
    }
  `)

  const layout = useLayout()

  const vacancyLiterals: { [key: string]: any } = {
    nl_NL: nl,
    en_US: en,
    default: en,
  }

  const posts = vacancyLiterals[layout.locale || 'default'].edges
  const [shownPosts, setShowsPosts] = React.useState(posts)
  const [customFilters, setCustomFilters] = React.useState([])
  const mobile = useMedia(`(max-width: 991px)`)
  const [openFilter, setOpenFilter] = React.useState(false)

  React.useEffect(() => {
    layout.setIsScrollable(!openFilter)
  }, [openFilter])

  const categories: Array<{
    filterType: string
    filters: Array<string>
  }> = [
    {
      filterType: 'function',
      filters: [],
    },
    {
      filterType: 'niveau',
      filters: [],
    },
    {
      filterType: 'type',
      filters: [],
    },
  ]

  posts.forEach(({ node }: any) => {
    const knownFilters = Object.keys(node.profilepreview || {})

    knownFilters.forEach((kf: any) => {
      categories.forEach((c: any) => {
        if (c.filterType === kf) {
          const filter = node.profilepreview[kf]

          // Check if the filter is not already in the filters array
          if (!c.filters.includes(filter[0])) {
            c.filters.push(filter[0])
          }
        }
      })
    })
  })

  React.useEffect(() => {
    const functionCopy = posts.filter(({ node }: any) => {
      let response = true

      const functionFilters = customFilters.filter((cf) =>
        categories[0].filters.includes(cf)
      )

      if (functionFilters.length > 0) {
        response = false

        functionFilters.forEach((cf) => {
          if (cf === node.profilepreview?.function[0]) {
            response = true
          }
        })
      }

      return response
    })

    const niveauCopy = functionCopy.filter(({ node }: any) => {
      let response = true

      const niveauFilters = customFilters.filter((cf) =>
        categories[1].filters.includes(cf)
      )

      if (niveauFilters.length > 0) {
        response = false

        niveauFilters.forEach((cf) => {
          if (cf === node.profilepreview?.niveau[0]) {
            response = true
          }
        })
      }

      return response
    })

    const typeCopy = niveauCopy.filter(({ node }: any) => {
      let response = true

      const typeFilters = customFilters.filter((cf) =>
        categories[2].filters.includes(cf)
      )

      if (typeFilters.length > 0) {
        response = false

        typeFilters.forEach((cf) => {
          if (cf === node.profilepreview?.type[0]) {
            response = true
          }
        })
      }

      return response
    })

    setShowsPosts(typeCopy)
  }, [customFilters])

  return (
    <StyledProfiles>
      <BlogBink
        // @ts-ignore
        posts={shownPosts as BlogBinkPosts}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="container py-5">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              {mobile ? (
                <>
                  <FilterOpenButton>
                    <div
                      role="button"
                      tabIndex={-1}
                      onKeyDown={() => setOpenFilter(!openFilter)}
                      onClick={() => setOpenFilter(!openFilter)}
                    >
                      {!openFilter ? (
                        <div>
                          Open filter<b>_</b>
                        </div>
                      ) : (
                        <div>
                          {layout.locale === 'nl_NL' ? (
                            <>
                              {`Laat ${shownPosts.length} profiel${
                                shownPosts.length === 1 ? `` : `en`
                              } zien`}
                            </>
                          ) : (
                            <>
                              {`Show ${shownPosts.length} profile${
                                shownPosts.length === 1 ? `` : `s`
                              }`}
                            </>
                          )}

                          <b>_</b>
                        </div>
                      )}
                    </div>
                  </FilterOpenButton>
                  {openFilter && (
                    <BlogFilters
                      filters={customFilters}
                      setFilters={setCustomFilters}
                      categories={categories}
                    />
                  )}
                </>
              ) : (
                <BlogFilters
                  filters={customFilters}
                  setFilters={setCustomFilters}
                  categories={categories}
                />
              )}
            </div>
            <div className="col-lg-8">
              <BlogGrid fields={fields} />
            </div>
          </div>
        </div>
      </BlogBink>
    </StyledProfiles>
  )
}

export default Profiles

interface BlogFiltersProps {
  categories: Array<{
    filterType: string
    filters: Array<string>
  }>
  filters: Array<string>
  setFilters: Function
}

const FilterTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.text.primaryLight};
  padding: 40px 0 15px 0;

  @media screen and (max-width: 991px) {
    padding: 20px 0 15px 0;
  }
`

const FilterOption = styled.div<{ isSelected: boolean }>`
  font-size: 16px;
  line-height: 30px;
  padding-left: 25px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.color.text.dark};
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    height: 15px;
    width: 15px;
    border: 1px solid ${({ theme }) => theme.color.greyDark};
    position: absolute;
    left: 0px;
    top: 7px;

    ${({ isSelected, theme }) =>
      isSelected
        ? css`
            background-color: ${theme.color.secondary};
          `
        : css`
            background-color: ${theme.color.light};
          `}
  }
`

const StyledBlogFilters = styled.div<{ darkmode: boolean }>`
  background-color: ${({ theme }) => theme.color.grey};
  transition: all 0.3s ease;
  padding: 25px 40px 200px 0;
  margin-top: -150px;
  max-width: 350px;
  position: relative;
  z-index: 5;

  ${({ darkmode }) =>
    darkmode
      ? css`
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
        `
      : css`
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        `}

  &::before {
    content: '';
    background-color: ${({ theme }) => theme.color.grey};
    transition: all 0.3s ease;
    width: 100px;
    left: -100px;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 5;
  }

  @media screen and (max-width: 991px) {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 15;
    margin-top: unset;
    max-width: unset;
    padding: 50px 100px;
  }

  @media screen and (max-width: 575px) {
    padding: 50px 50px;
  }
`

const Title = styled.div`
  font-size: 30px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 70px;
  color: ${({ theme }) => theme.color.secondary};
  font-family: ${({ theme }) => theme.font.family.secondary};
`

const BlogFilters: React.FC<BlogFiltersProps> = ({
  filters,
  setFilters,
  categories,
}) => {
  const handleFilter = (filter: string) => {
    // @ts-ignore
    const n = [...filters]
    if (filters.includes(filter)) {
      n.splice(filters.indexOf(filter), 1)
    } else {
      n.push(filter)
    }

    setFilters(n)
  }
  const layout = useLayout()

  return (
    <StyledBlogFilters darkmode={layout.darkMode}>
      <Title>Filters</Title>
      {categories.map((category, catIndex) => (
        <div key={`cat-${catIndex}`}>
          <FilterTitle>{category.filterType}</FilterTitle>
          {category.filters.map((filter, index) => (
            <FilterOption
              onClick={() => handleFilter(filter)}
              isSelected={filters.includes(filter)}
              key={`${filter}-${index}`}
              id={filter}
              className="mr-3"
            >
              {filter}
            </FilterOption>
          ))}
        </div>
      ))}
    </StyledBlogFilters>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledBlogGrid = styled.div`
  margin-top: -150px;
  position: relative;
  z-index: 5;

  @media screen and (max-width: 991px) {
    margin-top: -200px;
  }
`

const NothingFound = styled.div<{ darkmode: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  padding: 40px;
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.text.fatPrimaryLight};
  font-size: 30px;

  & p {
    font-size: 20px;
  }

  ${({ darkmode }) =>
    darkmode
      ? css`
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
        `
      : css`
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        `}
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()
  const layout = useLayout()

  return (
    <StyledBlogGrid>
      <div className="row">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <>
              <div key={post.node.id} className="col-lg-12 mb-3">
                <BlogGridPost node={node} blogFields={fields} />
              </div>
            </>
          )
        })}
        {blogBink.posts.length === 0 && (
          <>
            <div className="col-lg-12 mb-3">
              <NothingFound darkmode={layout.darkMode}>
                <div>Helaas zijn er geen profielen voor deze filters</div>
                <div className="d-flex pt-4 justify-content-between">
                  <p className="mb-0">Wel benieuwd naar onze diensten?</p>
                  <Button
                    className="filled secondary hover-secondary"
                    to="/contact/"
                  >
                    Neem contact op
                  </Button>
                </div>
              </NothingFound>
            </div>
          </>
        )}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <Button to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </Button>
        </div>
      )}
    </StyledBlogGrid>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpProfiel
}

const StyledBlogGridPost = styled.div<{ darkmode: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
  ${({ darkmode }) =>
    darkmode
      ? css`
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
        `
      : css`
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        `}

  padding: 40px 60px;
  position: relative;
  z-index: 2;

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-size: 20px;
    line-height: 20px;
  }

  & h3 {
    font-size: 16px;
    color: ${({ theme }) => theme.color.dark};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  @media screen and (max-width: 991px) {
    padding: 10px 15px;
  }
`

const Block = styled.div<{ darkmode: boolean }>`
  position: relative;

  & svg {
    margin-right: 10px;

    & path {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              fill: ${theme.color.secondary};
            `
          : css`
              fill: ${theme.color.primaryLight};
            `}
    }

    & rect {
      ${({ darkmode, theme }) =>
        darkmode
          ? css`
              stroke: ${theme.color.secondary};
            `
          : css`
              stroke: ${theme.color.primaryLight};
            `}
    }
  }
`

const TopVacancy = styled.div`
  position: absolute;
  box-shadow: inset 0px 0px 0px 2px
    ${({ theme }) => theme.color.border.primaryLight};
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  & > div {
    padding: 0 60px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.color.border.primaryLight};
    transition: all 0.3s ease;
    color: ${({ theme }) => theme.color.text.altLight};
    font-size: 25px;
    font-weight: ${({ theme }) => theme.font.weight.bold};

    & b {
      color: ${({ theme }) => theme.color.text.secondary};
    }
  }

  @media screen and (max-width: 991px) {
    & > div {
      padding: 0 50px;
      font-size: 16px;
    }
  }
`

const TitleWrapper = styled.div`
  @media screen and (max-width: 350px) {
    flex-wrap: wrap-reverse;

    & a,
    button {
      position: relative;
      left: -8px;
    }
  }
`

const SvgWrapper = styled.div`
  @media screen and (max-width: 575px) {
    width: 30px;
    display: flex;
    justify-content: flex-start;
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const layout = useLayout()

  return (
    <StyledBlogGridPost darkmode={layout.darkMode}>
      {node.profilepreview?.topvacancy === 'true' && (
        <TopVacancy>
          <div className="d-flex">
            {layout.locale === 'nl_NL' ? (
              <div>
                Topvacature<b>_</b>
              </div>
            ) : (
              <div>
                Top vacancy<b>_</b>
              </div>
            )}
          </div>
        </TopVacancy>
      )}
      <Block darkmode={layout.darkMode}>
        <TitleWrapper className="d-flex justify-content-between align-items-center">
          <h2>{node?.title}</h2>
          <Button
            arrow
            className="secondary transparent hover-primaryAlt"
            to={node?.uri || '/'}
          >
            {blogFields.readmoretext}
          </Button>
        </TitleWrapper>
        <div className="row pt-2">
          <h3 className="col-sm-3 d-flex align-items-center">
            <SvgWrapper>
              <Clock />
            </SvgWrapper>
            {node?.profilepreview?.type}
          </h3>
          <h3 className="col-sm-3 d-flex align-items-center">
            <SvgWrapper>
              <Pin />
            </SvgWrapper>
            {node?.profilepreview?.location}
          </h3>
          <h3 className="col-sm-3 d-flex align-items-center">
            <SvgWrapper>
              <Bars />
            </SvgWrapper>
            {node?.profilepreview?.niveau}
          </h3>
        </div>
        <div className="pt-lg-4 pt-2">
          <ParseContent content={node.profilepreview?.description || ''} />
        </div>
      </Block>
    </StyledBlogGridPost>
  )
}
