import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'

// Images
import Down from 'img/down.inline.svg'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import useLayout from 'hooks/useLayout'
import { LayoutContextProps } from 'components/layout/Layout'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledBlog = styled.section`
  background-color: ${({ theme }) => theme.color.light};
`

const Title = styled(ParseContent)`
  & h2 {
    @media screen and (min-width: 991px) {
      font-size: 75px;
      line-height: 70px;
    }
  }
`

const OtherBlog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.otherblogQueryQuery>(graphql`
    query otherblogQuery {
      allWpPost(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  const layout: LayoutContextProps = useLayout()

  return (
    <StyledBlog>
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="blog"
        limit={3}
        hideIds={[layout.pageId]}
      >
        <div className="container pt-lg-5 pt-3 mt-lg-5">
          <Title content={fields.title || ''} />
        </div>
        <div className="py-lg-5 pt-3 pb-4 mb-lg-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </StyledBlog>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledBlogGrid = styled.div`
  position: relative;
  z-index: 5;
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <StyledBlogGrid className="container">
      <div className="row mt-lg-0 mt-2">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-xl-4 col-md-6 mt-lg-5 mt-3">
              <BlogGridPost node={node} blogFields={fields} />
            </div>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </StyledBlogGrid>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPost
}

const Content = styled.div`
  position: absolute;
  width: 100%;
  bottom: 50px;
  z-index: 3;

  & p {
    font-size: 20px;
    line-height: 35px;
  }

  @media screen and (max-width: 991px) {
    & p {
      font-size: 16px;
      line-height: 28px;
    }
  }

  @media screen and (max-width: 575px) {
    bottom: 80px;
  }

  @media screen and (max-width: 350px) {
    bottom: 50px;
  }
`

const StyledBlogGridPost = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  border: 3px solid ${({ theme }) => theme.color.secondary};
  border-radius: 40px;
  position: relative;
  padding: 40px;
  margin: 0 25px;
  overflow: hidden;
  height: 460px;

  @media screen and (max-width: 991px) {
    padding: 20px;
    margin: 0;
  }

  @media screen and (max-width: 575px) {
    height: 400px;
  }

  @media screen and (max-width: 350px) {
    height: 300px;
  }
`

const ButtonWrapper = styled(Link)`
  position: absolute;
  z-index: 4;
  bottom: 20px;
  right: 20px;

  & svg {
    transform: rotate(-90deg);

    & circle {
      transition: all 0.3s ease;
    }

    & path {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    & svg {
      & circle {
        fill: ${({ theme }) => theme.color.primary};
      }

      & path {
        stroke: ${({ theme }) => theme.color.secondary};
      }
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 40px;

  & img {
    border-radius: 40px;
  }
`

const Gradient = styled.div`
  background: linear-gradient(
    180deg,
    rgba(13, 14, 115, 0) 26.56%,
    #09154f 100%
  );
  position: absolute;
  width: 100%;
  height: 366px;
  bottom: 0;
  left: 0;
  z-index: 2;
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <StyledBlogGridPost>
    <StyledPlaatjie image={node.recap?.image} alt="" />
    <Gradient />
    <Content>
      <ParseContent content={node.recap?.description || ''} />
    </Content>
    <ButtonWrapper to={node.uri || '/'}>
      <Down />
    </ButtonWrapper>
  </StyledBlogGridPost>
)

export default OtherBlog
